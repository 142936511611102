/* website: 3724-spinelli1
 * created at 2022-05-05 16:30 by jyburtschell
 */


// Import all makes and organization styles files
@import "../templates/groups/spinelli.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.groups__210{
    .listing-used-drivetrain{
      .InputCheckbox{
        input[type=checkbox]+label span{
          &:after{
            top: 5px;
          }
        }
      }
    }
    .listing-used-brands-container{
      .listing-used-brand{
        .InputCheckbox input[type=checkbox]+label{
          span:after{top: 5px !important;}
        }
      }
    }
  }
}